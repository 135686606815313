<template>
  <div class="ud-body">
    <a-card
      style="width: 100%"
      :tab-list="tabList"
      :bordered="false"
      :active-tab-key="key"
      @tabChange="(key) => onTabChange(key)"
    >
      <!-- 基础设置 -->
      <div v-if="key === '基础设置'">
        <a-form
          ref="form"
          :model="form.basic"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            :rules="{ required: true, trigger: ['change', 'blur'] }"
            label="是否开启分销功能"
            name="is_open"
          >
            <a-radio-group v-model:value="form.basic.is_open">
              <a-radio :value="1">开启</a-radio>
              <a-radio :value="0">关闭</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :rules="{ required: true, trigger: ['change', 'blur'] }"
            label="分销层级"
            name="level"
          >
            <a-radio-group v-model:value="form.basic.level">
              <a-radio :value="1">一级</a-radio>
              <a-radio :value="2">二级</a-radio>
              <a-radio :value="3">三级</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :rules="{ required: true, trigger: ['change', 'blur'] }"
            style="margin-bottom: 0"
            label="分销商内购"
            name="self_buy"
          >
            <div>
              <a-radio-group v-model:value="form.basic.self_buy">
                <a-radio :value="1">开启</a-radio>
                <a-radio :value="0">关闭</a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>注:分销商自己购买产品,获得一级佣金,上级获得二级佣金</div>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <a-button @click="save" type="primary">保存</a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 分销商条件 -->
      <div v-if="key === '分销商条件'">
        <a-form
          ref="form"
          :model="form.condition"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="成为分销商条件"
            name="become"
            :rules="{ required: true, trigger: ['change', 'blur'] }"
          >
            <a-radio-group v-model:value="form.condition.become">
              <a-radio :value="10">需后台审核</a-radio>
              <a-radio :value="20">无需审核</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            label="购买指定商品成为分销商"
            name="becomeBuyGoods"
            :rules="{ required: true, trigger: ['change', 'blur'] }"
            style="margin-bottom: 0"
          >
            <a-radio-group v-model:value="form.condition.becomeBuyGoods">
              <a-radio :value="0">关闭</a-radio>
              <a-radio :value="1">开启</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>注:购买指定商品付款后自动成为分销商,无需后台审核</div>
          </a-form-item>
          <a-form-item
            v-if="form.condition.becomeBuyGoods"
            :wrapper-col="{ offset: 4, span: 8 }"
          >
            <a-button type="default">选择商品</a-button>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <a-button @click="save" type="primary">保存</a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 佣金设置 -->
      <div v-if="key === '佣金设置'">
        <a-form
          ref="form"
          :model="form.commission"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            :rules="{ required: true, trigger: ['change', 'blur'] }"
            style="margin-bottom: 0"
            label="一级佣金比例"
            name="first_money"
          >
            <a-input-number
              :min="0"
              v-model:value="form.commission.first_money"
            ></a-input-number>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>注:佣金比例范围0%-100%</div>
          </a-form-item>
          <a-form-item
            :rules="{ required: true, trigger: ['change', 'blur'] }"
            style="margin-bottom: 0"
            label="二级佣金比例"
            name="second_money"
          >
            <a-input-number
              :min="0"
              v-model:value="form.commission.second_money"
            ></a-input-number>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>注:佣金比例范围0%-100%</div>
          </a-form-item>
          <a-form-item
            style="margin-bottom: 0"
            label="三级佣金比例"
            name="third_money"
            :rules="{ required: true, trigger: ['change', 'blur'] }"
          >
            <a-input-number
              :min="0"
              v-model:value="form.commission.third_money"
            ></a-input-number>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>注:佣金比例范围0%-100%</div>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <a-button type="primary">保存</a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 结算 -->
      <div v-if="key === '结算'">
        <a-form
          ref="form"
          :model="form.settlement"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="提现方式"
            name="pay_type"
            :rules="{ required: true, trigger: ['change', 'blur'] }"
            style="margin-bottom: 0"
          >
            <a-checkbox-group v-model:value="form.settlement.pay_type">
              <a-checkbox :value="10">银行卡支付</a-checkbox>
              <a-checkbox :value="20">支付宝支付</a-checkbox>
              <a-checkbox :value="30">微信支付[商家转账零钱]</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>
              <div>如使用支付宝或银行卡支付,需要线下手动打款</div>
              <div>如使用微信支付,则需要申请微信支付商家转账到零钱功能</div>
              <div>如使用微信支付,分销商必须在微信小程序申请提现</div>
            </div>
          </a-form-item>
          <a-form-item
            style="margin-bottom: 0"
            label="最低提现额度"
            name="min_money"
            :rules="{ required: true, trigger: ['change', 'blur'] }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              v-model:value="form.settlement.min_money"
            ></a-input-number
            ><span style="margin-left: 8px">元</span>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>如使用微信支付[商家转账零钱],则打款额度必须大于0.30元</div>
          </a-form-item>
          <a-form-item
            style="margin-bottom: 0"
            label="佣金结算周期"
            name="settle_days"
            :rules="{ required: true, trigger: ['change', 'blur'] }"
          >
            <a-input-number
              :min="0"
              v-model:value="form.settlement.settle_days"
            ></a-input-number
            ><span style="margin-left: 8px">天</span>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>
              佣金计算规则:用户下单付款->订单完成确认收货->超出售后期限->结算佣金
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <a-button @click="save" type="primary">保存</a-button>
          </a-form-item>
        </a-form>
      </div>

      <!-- 自定义文字 -->
      <div v-if="key === '自定义文字'">
        <a-divider orientation="left" style="margin: 40px 0 20px 0"
          >分销中心页面</a-divider
        >
        <a-form
          ref="form"
          :model="form.words.index"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="页面标题"
            name="value"
            :rules="{ required: true }"
          >
            <a-input v-model:value="form.words.index.title.value"></a-input>
          </a-form-item>
          <a-form-item
            label="非分销商提示"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.index.words.not_dealer.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="申请成为分销商"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.index.words.apply_now.value"
            ></a-input>
          </a-form-item>
          <a-form-item label="推荐人" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.index.words.referee.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="可提现佣金"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.index.words.money.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="待提现佣金"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.index.words.freeze_money.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="已提现金额"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.index.words.total_money.value"
            ></a-input>
          </a-form-item>
          <a-form-item label="去提现" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.index.words.withdraw.value"
            ></a-input>
          </a-form-item>
        </a-form>

        <a-divider orientation="left" style="margin: 40px 0 20px 0"
          >申请成为分销页面</a-divider
        >
        <a-form
          :model="form.words.apply"
          ref="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="页面标题"
            name="value"
            :rules="{ required: true }"
          >
            <a-input v-model:value="form.words.apply.title.value"></a-input>
          </a-form-item>
          <a-form-item
            label="请填写申请信息"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.apply.words.title.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="分销商申请协议"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.apply.words.license.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="申请成为经销商"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.apply.words.submit.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="审核中提示信息"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.apply.words.wait_audit.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="去商城逛逛"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.apply.words.goto_mall.value"
            ></a-input>
          </a-form-item>
        </a-form>

        <a-divider orientation="left" style="margin: 40px 0 20px 0"
          >分销订单页面</a-divider
        >
        <a-form
          :model="form.words.order"
          ref="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="页面标题"
            name="value"
            :rules="{ required: true }"
          >
            <a-input v-model:value="form.words.order.title.value"></a-input>
          </a-form-item>
          <a-form-item label="全部" name="value" :rules="{ required: true }">
            <a-input v-model:value="form.words.order.words.all.value"></a-input>
          </a-form-item>
          <a-form-item label="未结算" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.order.words.unsettled.value"
            ></a-input>
          </a-form-item>
          <a-form-item label="已结算" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.order.words.settled.value"
            ></a-input>
          </a-form-item>
        </a-form>

        <a-divider orientation="left" style="margin: 40px 0 20px 0"
          >我的团队页面</a-divider
        >
        <a-form
          :model="form.words.team"
          ref="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="页面标题"
            name="value"
            :rules="{ required: true }"
          >
            <a-input v-model:value="form.words.team.title.value"></a-input>
          </a-form-item>
          <a-form-item
            label="团队总人数"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.team.words.total_team.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="一级团队"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.team.words.first.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="二级团队"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.team.words.second.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="三级团队"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.team.words.third.value"
            ></a-input>
          </a-form-item>
        </a-form>

        <a-divider orientation="left" style="margin: 40px 0 20px 0"
          >提现明细页面</a-divider
        >
        <a-form
          :model="form.words.withdraw_list"
          ref="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="页面标题"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.withdraw_list.title.value"
            ></a-input>
          </a-form-item>
          <a-form-item label="全部" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.withdraw_list.words.all.value"
            ></a-input>
          </a-form-item>
          <a-form-item label="审核中" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.withdraw_list.words.apply_10.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="审核通过"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.withdraw_list.words.apply_20.value"
            ></a-input>
          </a-form-item>
          <a-form-item label="驳回" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.withdraw_list.words.apply_30.value"
            ></a-input>
          </a-form-item>
          <a-form-item label="已打款" name="value" :rules="{ required: true }">
            <a-input
              v-model:value="form.words.withdraw_list.words.apply_40.value"
            ></a-input>
          </a-form-item>
        </a-form>

        <a-divider orientation="left" style="margin: 40px 0 20px 0"
          >申请提现页面</a-divider
        >
        <a-form
          :model="form.words.withdraw_apply"
          ref="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="页面标题"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.withdraw_apply.title.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="可提现佣金"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.withdraw_apply.words.capital.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="提现金额"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.withdraw_apply.words.money.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="请输入要提取的金额"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="
                form.words.withdraw_apply.words.money_placeholder.value
              "
            ></a-input>
          </a-form-item>
          <a-form-item
            label="最低提现佣金"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.withdraw_apply.words.min_money.value"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="提交申请"
            name="value"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.words.withdraw_apply.words.submit.value"
            ></a-input>
          </a-form-item>
        </a-form>

        <a-divider orientation="left" style="margin: 40px 0 20px 0"
          >推广二维码</a-divider
        >
        <a-form
          :model="form.words.poster"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="页面标题"
            name="value"
            :rules="{ required: true }"
          >
            <a-input v-model:value="form.words.poster.title.value"></a-input>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <a-button @click="save" type="primary">保存</a-button>
          </a-form-item>
        </a-form>
      </div>

      <!-- 申请协议 -->
      <div v-if="key === '申请协议'">
        <a-form
          ref="form"
          :model="form.license"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="分销商申请协议"
            name="license"
            :rules="{ required: true }"
          >
            <a-textarea :rows="5" v-model:value="form.license.license">
            </a-textarea>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <a-button type="primary">保存</a-button>
          </a-form-item>
        </a-form>
      </div>

      <!-- 页面背景图 -->
      <div v-if="key === '页面背景图'">
        <a-form
          ref="form"
          :model="form.license"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-item
            label="分销中心首页"
            name="license"
            :rules="{ required: true }"
            style="margin-bottom: 0"
          >
            <a-tooltip placement="top">
              <template #title>
                <span>建议尺寸:宽750高度200</span>
              </template>
              <div class="image-box">
                <img
                  class="img"
                  style="width: 100%; height: 100%; border-radius: 10px"
                  :src="form.background.index"
                  alt=""
                />
                <div class="image-overlay"></div>
                <span class="replace">替换</span>
              </div>
            </a-tooltip>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>尺寸:宽750像素高度不限</div>
          </a-form-item>
          <a-form-item
            label="申请成为分销商页"
            name="apply"
            style="margin-bottom: 0"
            :rules="{ required: true }"
          >
            <a-tooltip placement="top">
              <template #title>
                <span>建议尺寸:宽750高度200</span>
              </template>
              <div class="image-box">
                <img
                  class="img"
                  style="width: 100%; height: 100%; border-radius: 10px"
                  :src="form.background.apply"
                  alt=""
                />
                <div class="image-overlay"></div>
                <span class="replace">替换</span>
              </div>
            </a-tooltip>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>尺寸:宽750像素高度不限</div>
          </a-form-item>
          <a-form-item
            label="申请提现页"
            name="withdraw_apply"
            style="margin-bottom: 0"
            :rules="{ required: true }"
          >
            <a-tooltip placement="top">
              <template #title>
                <span>建议尺寸:宽750高度200</span>
              </template>
              <div class="image-box">
                <img
                  class="img"
                  style="width: 100%; height: 100%; border-radius: 10px"
                  :src="form.background.withdraw_apply"
                  alt=""
                />
                <div class="image-overlay"></div>
                <span class="replace">替换</span>
              </div>
            </a-tooltip>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <div>尺寸:宽750像素高度不限</div>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 8 }">
            <a-button @click="save" type="primary">保存</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-card>
  </div>
</template>

<script>
import * as dealerSettingApi from '@/api/dealer/setting.js'
export default {
  data() {
    return {
      corpId: 0,
      form: {
        basic: {
          is_open: 1,
          level: 3,
          self_buy: 0
        }, // 基础设置表单
        condition: {
          become: 10,
          becomeBuyGoods: 0,
          becomeBuyGoodsIds: [],
          downline: 10
        }, // 分销商条件表单
        commission: {
          first_money: 0,
          second_money: 0,
          third_money: 0
        }, // 佣金设置表单
        settlement: {
          pay_type: [20, 10],
          min_money: 10.0,
          settle_days: 10
        }, // 结算表单
        words: {
          index: {
            title: { default: '分销中心', value: '推广中心' },
            words: {
              apply_now: {
                default: '立即加入',
                value: '立即加入'
              },
              freeze_money: {
                default: '待提现佣金',
                value: '待体现'
              },
              money: {
                default: '可提现佣金',
                value: '可提现'
              },
              not_dealer: {
                default: '你还不是分销商,请先提交申请',
                value: '你还不是推广大使,请先提交申请'
              },
              referee: {
                default: '推荐人',
                value: '推荐人'
              },
              total_money: {
                default: '已提现金额',
                value: '已提现金额'
              },
              withdraw: {
                default: '去提现',
                value: '去提现'
              }
            }
          },
          apply: {
            title: { default: '申请成为分销商', value: '申请成为分销商' },
            words: {
              goto_mall: {
                default: '去商城逛逛',
                value: '去商城逛逛'
              },
              license: {
                default: '分销商申请协议',
                value: '分销商申请协议'
              },
              submit: {
                default: '申请成为经销商',
                value: '申请成为经销商'
              },
              title: {
                default: '请填写申请信息',
                value: '请填写申请信息'
              },
              wait_audit: {
                default: '您的申请已受理，正在进行信息核验，请耐心等待',
                value: '您的申请已受理，正在进行信息核验，请耐心等待。'
              },
              total_money: {
                default: '已提现金额',
                value: '已提现金额'
              },
              withdraw: {
                default: '去提现',
                value: '去提现'
              }
            }
          },
          order: {
            title: { default: '分销订单', value: '分销订单' },
            words: {
              settled: {
                default: '已结算',
                value: '已结算'
              },
              unsettled: {
                default: '未结算',
                value: '未结算'
              },
              all: {
                default: '全部',
                value: '全部'
              }
            }
          },
          team: {
            title: { default: '我的团队', value: '我推荐的' },
            words: {
              first: {
                default: '一级团队',
                value: '一级团队'
              },
              total_team: {
                default: '团队总人数',
                value: '推荐总人数'
              },
              second: {
                default: '二级团队',
                value: '二级团队'
              },
              third: {
                default: '三级团队',
                value: '三级团队'
              }
            }
          },
          withdraw_list: {
            title: { default: '提现明细', value: '提现明细' },
            words: {
              all: {
                default: '全部',
                value: '全部'
              },
              apply_10: {
                default: '审核中',
                value: '审核中'
              },
              apply_20: {
                default: '审核通过',
                value: '审核通过'
              },
              apply_30: {
                default: '驳回',
                value: '驳回'
              },
              apply_40: {
                default: '已打款',
                value: '已打款'
              }
            }
          },
          withdraw_apply: {
            title: { default: '申请提现', value: '申请提现' },
            words: {
              capital: {
                default: '可提现佣金',
                value: '可提现佣金'
              },
              min_money: {
                default: '最低提现佣金',
                value: '最低提现佣金'
              },
              money: {
                default: '提现金额',
                value: '提现金额'
              },
              money_placeholder: {
                default: '请输入要提取的金额',
                value: '请输入要提取的金额'
              },
              submit: {
                default: '提交申请',
                value: '提交申请'
              }
            }
          },
          poster: {
            title: {
              default: '推广二维码',
              value: '推广二维码'
            }
          }
        }, // 自定义文字表单
        license: {
          license: '1.xxxxxxx\r\n2.xxxxxxx\r\n3.xxxxxxx'
        }, // 申请协议表单
        background: {
          apply:
            'https://shopyo.teampoint.cn/assets/store/img/dealer/dealer-bg.png',
          index:
            'https://shopyo.teampoint.cn/assets/store/img/dealer/dealer-bg.png',
          withdraw_apply:
            'https://shopyo.teampoint.cn/assets/store/img/dealer/dealer-bg.png'
        } // 页面背景图片表单
      },
      key: '基础设置',
      tabList: [
        {
          key: '基础设置',
          tab: '基础设置'
        },
        // {
        //   key: '分销商条件',
        //   tab: '分销商条件'
        // },
        {
          key: '佣金设置',
          tab: '佣金设置'
        },
        {
          key: '结算',
          tab: '结算'
        },
        {
          key: '自定义文字',
          tab: '自定义文字'
        },
        {
          key: '申请协议',
          tab: '申请协议'
        },
        {
          key: '页面背景图',
          tab: '页面背景图'
        }
      ]
    }
  },
  mounted() {
    this.corpId = this.getCorpId()
    this.getDealerSettingData()
  },
  methods: {
    getDealerSettingData() {
      dealerSettingApi.data({ corpId: this.corpId }).then((res) => {
        this.form = Object.assign({},this.form,res.data)
      })
    },
    onTabChange(key) {
      this.key = key
    },
    save() {
      this.form.corpId = this.corpId
      this.$refs.form
        .validate()
        .then(() => {
          dealerSettingApi
            .save(this.form)
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(res.msg)
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.$message.error(e.message)
            })
        })
        .catch((e) => {
          console.log('校验失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.image-box {
  width: 550px;
  height: 150px;
  border-radius: 10px;
  position: relative;
  &:hover .image-overlay {
    display: block;
  }
  &:hover .replace {
    display: block;
  }
  .image-overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0.5;
  }

  .replace {
    padding: 2px 15px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 3;
    display: none;
    transform: translate(-50%, -50%);
    cursor: default;
  }
}
</style>
