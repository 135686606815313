import request from '@/utils/request'

const api = {
  page: '/frm/corp/dealer/setting/page',
  save: '/frm/corp/dealer/setting/save',
  update: '/frm/corp/dealer/setting/update',
  data: '/frm/corp/dealer/setting/data'
}

export function data(params) {
  return new Promise((resolve, reject) => {
    request.get(api.data, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch((e) => {
      reject(e)
    })
  })
}

export const page = (params) => {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data) {
  return new Promise((resolve, reject) => {
    request.post(api.save, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
